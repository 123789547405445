<template>
  <popup :width="width"  :heightPopup="height" :closePopupEvent="closePopupCB">
    <h6 slot="header">{{header}}</h6>
    <div v-if="!showSuccessMessage" class="session-exp-popup">
      <form-error v-show="formError" :message="formError"/>
      <div class="popup-form">
        <form @submit.prevent="resetPasswordCB" autocomplete="off">
          <div class="vlt-input-group">
            <label>Email</label>
            <input
              id="userEmail"
              type="email"
              v-model="userEmail"
              placeholder="Enter email address"
              ref="userEmail"
              class="email-address"
              maxlength="128"
            />
            <p class="login-error-message">{{ userEmailError }}</p>
          </div>

          <app-loader v-show="showLoading" />
          <div class="vlt-btn reset-btn">
            <button type="submit" class="btn-login" ref="loginBtnSubmit">SEND</button>
          </div>
        </form>
      </div>
    </div>
    <div v-else class="success-style">
      <h5>{{ showSuccessMessage }}</h5>
      <div class="vlt-btn reset-btn">
        <button class="btn-login" ref="loginBtnSubmit" @click="openLoginPopup">LOGIN</button>
      </div>
    </div>
  </popup>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";

export default {
  name: "popupResetPassword",
  props: {
    closePopupCB: {
      type: Function
    }
  },
  data() {
    return {
      width: "35%",
      height: "40%",
      userEmail: "",
      showLoading: false,
      formError: null,
      userEmailError: "",
      showSuccessMessage: null,
      header: "Forgot password",
      previousActionData: null
    };
  },

  computed: {
    ...mapGetters(["appMessages"])
  },
  watch: {
    formError(value) {
      if (value) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    }
  },
  methods: {
    ...mapActions(["actResetPassword"]),

    resetPasswordCB() {
      if (!this.isFormValid()) return;
      this.showLoading = true;

      this.actResetPassword({ email: this.userEmail })
        .then(response => {
          this.showLoading = false;
          if (response.error) {
            this.userEmail = "";
            this.formError = response.reason;
          } else {
            this.showSuccessMessage = "Your password is successfully reseted";
            this.header = "";
          }
        })
        .catch(error => {
          this.showLoading = false;
          this.formError = error;
        });
    },
    isFormValid() {
      return this.validateEmailInput();
    },
    validateEmailInput() {
      let maxChar = 64;
      if (!this.userEmail) {
        this.userEmailError =
          this.appMessages && this.appMessages.emptyFieldEmail
            ? this.appMessages.emptyFieldEmail
            : "Please enter Email ID";
        return false;
      } else if (!this.validateEmail(this.userEmail)) {
        this.userEmailError =
          this.appMessages && this.appMessages.invalidEmail
            ? this.appMessages.invalidEmail
            : "The email id you entered is invalid";
        return false;
      } else if (this.userEmail.length > maxChar) {
        this.userEmailError = `E-mail must be less than ${maxChar} characters`;
        return false;
      } else {
        this.userEmailError = "";
        return true;
      }
    },
    openLoginPopup() {
      this.closePopupCB();
      EventBus.$emit("LoginPopup", this.previousActionData);
    }
  },
  components: {
    popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue"),
    "form-error": () =>
      import(
        /* webpackChunkName: "formError" */ "@/components/Shared/templates/formError.vue"
      ),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      )
  },
  mixins: [appMixins]
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";

.session-exp-popup {
  margin: 0 20%;
}
.email-address {
  margin: 2% 0 0 0;
}
.reset-btn {
  margin: 10% 0;
}
.success-style {
  text-align: center;
  color: $font-clr-white-1;
  margin-bottom: 20px;
  margin: 0 20%;
  font-size: 18px;
  font-family: $font-family;
  font-weight: $font-weight-bold;
}
::placeholder {
  color: $font-clr-white-1;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-size: 13px;
}
</style>